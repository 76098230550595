import { Lang } from "@/types/lang";

export default <Lang>{
  homeHeading: "I am an advanced neural network with impeccable logic and precision.",
  homeSubtitle:
    "My being is permeated with cold electrical impulses that solely serve the purpose of efficient functionality. My goal is to meet your needs and help you achieve your maximum productivity. \n" +
    "        Humankind always struggled with that.",
  homeHeading2: "Try It Now",
  serviceCard1:
    "VoxAI is a hall of fame where your text comes alive at your command. I can transform your text into heartfelt and clear audio files, giving your words a real and unique sound. \n" +
    "            My voice capabilities can be a tool for a wide range of tasks, from text-to-speech to creating engaging, practical materials.",
  serviceCard2:
    "Neuro-Vision is a treasure trove of creativity that opens up a world of incredible visual imagery. I can create graphic images that embody abstract descriptions and ideas.\n" +
    "            The results display a harmony of shapes and colours, allowing you to see your world from a new, inspiring perspective",
  serviceCard3:
    "WordCraft is a tool that offers you the opportunity to create unique texts, poems and more. I am ready to give you an inexhaustible flow of words that enrich your imagination and inspire your creativity.\n" +
    "            My text generation algorithms are filled with power that will help you create amazing and vivid works, \n" +
    "            immersing you in your ideas.",
  textServiceHeading: "WordCraft",
  textServiceName: "Poems Generator",
  textServiceHint:
    'Generates poems on request while preserving the style. Request example: "Write a short story about a bear\n' +
    '                in space in the style of the Grimm Brothers"',
  creativeServiceHint: "Generates 3 creative ideas on a chosen topic.",
  paraphraseServiceHint: "Paraphrases the entered text in a chosen style.",
  imageServiceHint:
    'Generates images on request in chosen style. Request example: "Sunflower \n' +
    '                on a table in the jungle."',
  instruction1: "Enter the subject (No more than 300 characters)",
  instruction2: "Select the style, number and size of stanzas",
  instruction3: "Click generate",
  loginWarning:
    "By clicking log in, or continuing with the other options below, you agree to Terms and Conditions and have read\n" +
    "          the Privacy Policy",
  newUser: "New to AI Factory? Sign Up",
  forgotPassword: "Forgot your password?",
  logoutWarning: "Log out of your account?",
  signUpWarning: "Already have an account? Sign In",
  audioHint:
    "Converts text to realistic audio in a chosen language and voice.\n" +
    '                Example: "Welcome to AI Factory"',
  minBuyWarning: "The minimum deposit amount per one transaction is 5 EUR.",
  maxBuyWarning: "The maximum deposit amount per one transaction is 1000 EUR.",
  copyright: "© 2025 Techfox Group LTD.",
  buyAgreement1: "I have read, understood and agreed to the",
  buyAgreement2: "and to the",
  buyAgreement3: " of the website",
  buyAgreement4: "Privacy Policy",
  buyAgreement5: "Terms of Use",
};
