import { Lang } from "@/types/lang";

export default <Lang>{
  homeHeading: "Sono una rete neurale avanzata con una logica impeccabile e precisione.",
  homeSubtitle:
    "La mia esistenza è permeata di fredde impulsi elettrici che servono esclusivamente allo scopo di un funzionamento efficiente. Il mio obiettivo è soddisfare le tue esigenze e aiutarti a raggiungere la massima produttività. \n" +
    " L'umanità ha sempre lottato con questo.",
  homeHeading2: "Provalo adesso",
  serviceCard1:
    "VoxAI è una galleria della fama in cui il tuo testo prende vita al tuo comando. Posso trasformare il tuo testo in file audio sinceri e chiari, dando alle tue parole un suono reale e unico. \n" +
    " Le mie capacità vocali possono essere uno strumento per una vasta gamma di attività, dalla sintesi vocale alla creazione di materiale coinvolgente e pratico.",
  serviceCard2:
    "Neuro-Vision è uno scrigno pieno di creatività che apre un mondo di immagini visive incredibili. Posso creare immagini grafiche che incarnano descrizioni e idee astratte. \n" +
    " I risultati mostrano un'armonia di forme e colori, consentendoti di vedere il tuo mondo da una prospettiva nuova e inspiratrice.",
  serviceCard3:
    "WordCraft è uno strumento che ti offre l'opportunità di creare testi, poesie e altro unici. Sono pronto a offrirti un flusso inesauribile di parole che arricchiranno la tua immaginazione e ispireranno la tua creatività. \n" +
    " I miei algoritmi di generazione del testo sono pieni di potenza che ti aiuterà a creare opere straordinarie e vivide, immergendoti nelle tue idee.",
  textServiceHeading: "Text Services",
  // textServiceName: "Generating Poems",
  textServiceName: "Poem Generator",
  textServiceHint:
    'Genera poesie su richiesta preservando lo stile. Esempio di richiesta: "Scrivi una breve storia su un orso\n' +
    "                nello spazio nello stile dei fratelli Grimm",
  imageServiceHint:
    'Genera immagini su richiesta nello stile scelto. Esempio di richiesta: "Girasole \n' +
    '                su un tavolo nella giungla."',
  creativeServiceHint: "Genera 3 idee creative su un argomento scelto.\n",
  paraphraseServiceHint: "Parafrasa il testo inserito in uno stile scelto.\n",
  instruction1: "Inserire l'oggetto (non più di 300 caratteri)",
  instruction2: "Selezionare lo stile, il numero e la dimensione delle strofe.",
  instruction3: "Fare clic su genera",
  loginWarning:
    "Facendo clic su accedi, o continuando con le altre opzioni sottostanti, si accettano i Termini di servizio e si è presa visione\n" +
    "          dell'Informativa sulla privacy",
  newUser: "Nuovo su AI Factory? Iscriviti",
  forgotPassword: "Avete dimenticato la password?",
  logoutWarning: "Uscire dal proprio account?",
  signUpWarning: "Hai già un account? Accedi",
  audioHint:
    "Converte il testo in audio realistico nella lingua e nella voce prescelte.\n" +
    '                Esempio: "Benvenuti a AI Factory"',
  minBuyWarning: "L'importo minimo del deposito per una transazione è di 5 EUR.",
  maxBuyWarning: "L'importo massimo del deposito per una transazione è di 1000 EUR.",
  copyright: "© 2025 Techfox Group LTD.",
  buyAgreement1: "Ho letto, compreso e accettato l'",
  buyAgreement2: "e al",
  buyAgreement3: " del sito web",
  buyAgreement4: "Politica sulla Riservatezza",
  buyAgreement5: "Termini di utilizzo",
};
